<template>
  <div class="app_con">
    <app-list :remote="remote" :props="props" :btnFlag="btnFlag" :formProps="formProps" :searchQuery="searchQuery"
      :apiName="apiName" :rules="rules" ref="appList" @selectChange="getSelectChangeInfo">
      <div class="m-t-10" slot="searchSlot">
        <search-bar :props="searchProps" @searchChange="searchChange"></search-bar>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api/common";

export default {
  data() {
    return {
      selecTionArr: [],
      remote: api,
      apiName: "/user",
      searchVal: "",
      bntLoading: false,
      btnFlag: { addShow: true, delShow: false },
      searchQuery: { type: 0, rand: 0, phone: '', name: '' },
      // searchControl:{
      //   firstSoltSpan: [10, 10, 10, 10],
      //     secondSoltSpan: [10, 10, 10, 10],
      //     thirdSoltSpan: [10, 10, 10, 10],
      // },
      rules: {
        //传递给APPlist的表单验证
      },
      searchProps: [
        { type: "input", label: "电话", prop: "phone" },
        { type: "input", label: "用户名", prop: "name" },
      ],
      props: [
        {
          label: "用户名",
          prop: "name",
          align: "center",
        },

        {
          label: "id",
          prop: "id",
          align: "center",
        },
        {
          label: "电话",
          prop: "phone",
          align: "center",
        },
        {
          label: "积分",
          prop: "integral",
          align: "center",
        },


        {
          label: "创建时间",
          prop: "createtime",
          align: "center",
        },

        {
          label: "操作",
          prop: "qrcode",
          align: "center",
          width: 250,
          type: "operationLink",
          formatter: function (row) {
            const buttonList = [
              {
                text: "编辑",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-edit",
                type: "primary",
                handlerType: "update",
                handler: function () { },
              },

            ];

            return buttonList;
          },
        },
      ],
      formProps: [
        {
          label: "电话",
          type: "input",
          prop: "phone",

          span: 12
        },
        {
          label: "积分",
          type: "input",
          prop: "integral",

          span: 12
        },
      ],
      goodsList: [],
      categoryList: [],
    };
  },
  created() { },
  mounted() {

  },
  methods: {

    searchChange(e) {
      for (const key in e) {
        if (Object.hasOwnProperty.call(e, key)) {
          this.searchQuery[key] = e[key]
        }
      }
      this.searchQuery.rand++
      console.log(this.searchQuery)
    },
    async getGoodsList() {
      let loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      try {
        let res = await api.commonGet({
          apiName: "/goodsBasicSetting",
          pageSize: 100,
        });

        this.goodsList = [];
        res.data.data.forEach((element) => {
          this.goodsList.push({ label: element.name, value: element.id });
        });
      } catch (error) {
        console.log(error);
      } finally {
        loading.close();
      }
    },
    async getCategoryList() {
      let loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      try {
        let res = await api.commonGet({
          apiName: "/classificationGoods",
          pageSize: 100,
        });

        res.data.forEach((element) => {
          this.categoryList.push({ label: element.name, value: element.id });
        });
      } catch (error) {
        console.log(error);
      } finally {
        loading.close();
      }
    },
    getSelectChangeInfo(e, field) {
      if (field == "linkType") {
        //分类
        if (e == 1) {
          this.formProps[3] = {
            label: "链接",
            type: "select",
            prop: "url",
            selectData: this.categoryList,
          };
        } else if (e == 2) {
          this.formProps[3] = {
            label: "链接",
            type: "select",
            prop: "url",
            selectData: this.goodsList,
          };
        } else {
          this.formProps[3] = {
            label: "链接",
            type: "input",
            prop: "url",
          };
        }
      }
      console.log(e, field);
    },
    jumpToDetail(id) {
      this.$router.push({ path: "/goods/create", query: { id: id } });
    },

    async setGoodsPutAway(value) {
      if (!this.$refs.appList.selecTionArr.length) {
        this.$message.error("请至少选择一条数据！");
        return;
      }

      let loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });

      try {
        let ids = [];
        this.$refs.appList.selecTionArr.forEach((element) => {
          ids.push(element.id);
        });

        let res = await api.common.commonPost({
          apiName: "/goodsBasicSetting/isPutaway",
          ids: ids,
          isPutaway: value,
        });
        this.$message.success(res.message);
        this.searchQuery.refreshNum++;
      } catch (error) {
        console.log(error);
      } finally {
        loading.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.changeCon p {
  color: #999;
}

.changeCon b {
  margin: 0 10px;
  color: #66b1ff;
}

.search {
  display: flex;
}

.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
